/* eslint-disable unicorn/no-for-loop */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { CampusIcon } from '@campus/components';
import { Chapter } from './components';
import { numberOfColumns } from './constants';
import { calculateChapterNumberWidthForEachColumn, calculateIndicatorMargin, getChapterIdFromListClickEvent, navigateToChapter } from './helpers';
import { Wrapper, Content, Indicator, Chapters } from './styles';
export const ChapterSelector = forwardRef(({ className, libraryObject, getTriggerElement }, ref) => {
    const contentRef = useRef(null);
    const chaptersRef = useRef(null);
    const [indicatorMargin, setIndicatorMargin] = useState(0);
    const [navigatingTo, setNavigatingTo] = useState(null);
    const [chapterNumberWidths, setChapterNumberWidths] = useState([]);
    useEffect(() => {
        const listElement = chaptersRef.current;
        const widths = calculateChapterNumberWidthForEachColumn(listElement);
        setChapterNumberWidths(widths);
    }, []);
    useEffect(() => {
        const trigger = getTriggerElement();
        const content = contentRef.current;
        setIndicatorMargin(calculateIndicatorMargin(trigger, content));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function onChaptersClick(event) {
        if (navigatingTo)
            return;
        const chapterId = getChapterIdFromListClickEvent(event);
        if (!chapterId)
            return;
        setNavigatingTo(chapterId);
        await navigateToChapter(chapterId, libraryObject);
        setNavigatingTo(null);
    }
    function renderChapters() {
        const chapters = libraryObject.chapters ?? [];
        if (chapters.length === 0)
            return null;
        const items = Array.from({ length: chapters.length });
        let chapterIndex = 0;
        for (let c = 0; c < numberOfColumns; ++c) {
            for (let i = c; i < chapters.length; i += numberOfColumns) {
                const chapter = chapters[chapterIndex];
                items[i] = (React.createElement(Chapter, { key: chapter.id, columnIndex: c, chapter: chapter, chapterIndex: chapterIndex, nonClickable: !!navigatingTo, numberWidth: chapterNumberWidths[c], isLoading: navigatingTo === chapter.id, lastOneInColumn: i + numberOfColumns >= chapters.length ||
                        chapterIndex === chapters.length - 1 }));
                chapterIndex += 1;
            }
        }
        return items;
    }
    return (React.createElement(Wrapper, { ref: ref, className: className },
        React.createElement(Indicator, { "$marginLeft": indicatorMargin },
            React.createElement(CampusIcon, { name: "chapterListIndicator" })),
        React.createElement(Content, { ref: contentRef },
            React.createElement("h4", null,
                libraryObject.discipline?.name,
                " - ",
                libraryObject.name,
                " -",
                ' ',
                libraryObject.grade?.name),
            React.createElement(Chapters, { ref: chaptersRef, onClick: onChaptersClick }, renderChapters()))));
});
ChapterSelector.displayName = 'ChapterList';
